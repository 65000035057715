form {
    position: relative;
    width: 100%;
    max-width: 500px;
    transition-property: opacity, transform;
    transition-duration: 0.35s;
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    margin: auto;
    padding-top: 20px;
}

form h3 {
    margin-left: 10px;
    margin-bottom: 20px;
}

.row {
    display: -ms-flexbox;
    display: flex;
    margin: 10px 0;
}

.field {
    position: relative;
    width: 100%;
    height: 50px;
    margin: 0 10px;
    border-bottom: 1px solid #80808045;
}
form .row .field input {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    padding-bottom: 7px;
    color: #32325d;
    background-color: transparent;
}
.submitButton {
    margin-left: 10px;
    width: 96%;
    padding: 10px 0;
    background: #03a644;
    color: white;
    font-size: 18px;
    margin-bottom: 20px;
}
.submitExistCard {
    width: initial;
    margin: auto;
    display: inherit;
    background: transparent;
    color: #03a644;
    border: 1px solid #03a644;
    padding: 10px 20px;
    border-radius: 5px;

}
.saveCard {
    margin-top: 11px;
}
